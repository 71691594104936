import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { TabTitle } from '../../utils/TabTitle';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { toast } from "react-toastify";
import { USER_TYPE } from '../../defs/User';

import UploadSlider from '../../feature/LSM/Dashboard/UploadSlider';
import Popup from '../../components/Popup/Popup';
import { AuthContext } from '../../context/auth';
import './LSM.scss';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import { PageDescription } from '../../utils/PageDescription';
import { ReactComponent as SettingsIcon } from '../../assets/icons/SettingsIcon.svg';


function LSM({ ...rest }) {
  const { user } = useContext(AuthContext);
  TabTitle(rest.title || 'KFC LSM');
  PageDescription('Dashboard', '')
  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonPopupInner, setButtonPopupInner] = useState(false);
  const [buttonPopupInnerCards, setButtonPopupInnerCards] = useState(false);
  const [buttonPopupInnerDelete, setButtonPopupInnerDelete] = useState(false);
  const [buttonPopupInnerEdit, setButtonPopupInnerEdit] = useState(false);


  const [currentEditItemID, setCurrentEditItemID] = useState(false);
  const [currentEditItemName, setCurrentEditItemName] = useState(false);
  const [currentEditType, setCurrentEditType] = useState(false);


  const [currentDeleteItemID, setCurrentDeleteItemID] = useState(false);
  const [currentDeleteItemName, setCurrentDeleteItemName] = useState(false);
  const [currentDeleteType, setCurrentDeleteType] = useState(false);

  let openPopup = () => {
    setButtonPopup(true)
  }

  let openPopupInner = () => {
    setButtonPopupInner(true)
  }
  let openPopupDelete = (id, name, type) => {
    setCurrentDeleteItemID(id)
    setCurrentDeleteItemName(name)
    setButtonPopupInnerDelete(true)
    setCurrentDeleteType(type)
  }

  let openEditPopup = (id, name, type) => {
    setCurrentEditItemID(id)
    setCurrentEditItemName(name)
    setButtonPopupInnerEdit(true)
    setCurrentEditType(type)
  }

  let openPopupInnerCard = () => {
    setButtonPopupInnerCards(true)
  }

  const [dashboardSlider, setDashboardSlider] = useState([[]]);
  const [dashboardCards, setDashboardCards] = useState([[]]);


  useEffect(() => {
    fetch(process.env.REACT_APP_API_SERVER + 'lsm-dashboard-slider', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setDashboardSlider(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    fetch(process.env.REACT_APP_API_SERVER + 'lsm-dashboard-cards', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log('dashboardCards',data);
        setDashboardCards(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [])

  let notifySuccess = () => toast.success('Success', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  let removeItemID = (id, type) => {
    if (currentDeleteType === 'slider') {
      fetch(process.env.REACT_APP_API_SERVER + 'remove-slide-dashboard', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id
        }),
      })
        .then(response => response.json())
        .then(result => {
          // console.log(result)
        })
        .catch(error => {
          console.error('Error:', error);
        });
      notifySuccess()
    } else if (currentDeleteType === 'card') {
      fetch(process.env.REACT_APP_API_SERVER + 'remove-card-dashboard', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id
        }),
      })
        .then(response => response.json())
        .then(result => {
          // console.log(result)
        })
        .catch(error => {
          console.error('Error:', error);
        });
      notifySuccess()
    }
  }

  let editItemID = (id, type) => {
    let editNewLink = document.querySelector('.edit-lsm-dashboard-card input').value;
    if (currentEditType === 'slider') {
      fetch(process.env.REACT_APP_API_SERVER + 'edit-slide-dashboard', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id,
          newLink: editNewLink
        }),
      })
        .then(response => response.json())
        .then(result => {
          // console.log(result)
        })
        .catch(error => {
          console.error('Error:', error);
        });
      notifySuccess()
    } else if (currentEditType === 'card') {
      fetch(process.env.REACT_APP_API_SERVER + 'edit-card-dashboard', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id,
          newLink: editNewLink
        }),
      })
        .then(response => response.json())
        .then(result => {
          // console.log(result)
        })
        .catch(error => {
          console.error('Error:', error);
        });
      notifySuccess()
    }
  }
  return (
    <div className={'lsm'}>

      {(() => {
        if (user.userRole === USER_TYPE.ADMIN) {
          return (
            <div className='lsm-dashboard-bar'>
              <button onClick={openPopup} className='settings-btn'>
                <SettingsIcon />
              </button>
            </div>
          )
        } else {
          return (
            <></>
          )
        }
      })()}

      <Popup style={{ maxWidth: '900px' }} trigger={buttonPopup} setTrigger={setButtonPopup}>
        <div className='lsm-popup-settings'>
          <h3>LSM Dashboard Settings</h3>
          {/*<p>You can add new slides and cards.</p>*/}
          <hr />
          <div className='lsm-popup-settings__row'>
            <div className='lsm-popup-settings__slider'>
              <div className='lsm-popup-settings__slider__header'>
                <h4>Slider</h4>
                <button onClick={openPopupInner} style={{ marginTop: '20px' }} type='submit'>Add Slide</button>
              </div>
              <div className='lsm-popup-settings__slider__body'>
                <div className={'lsm-popup-settings__slider__body__slider'}>
                  <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{delay: 5000}}>
                    {
                      dashboardSlider.map((element, key) => (
                        <SwiperSlide key={key}>
                          {element.fileUrl ? (
                            <div className={'lsm-popup-settings__slider__body__slider__content'}>
                              <img src={element.fileUrl} alt="slide-01" />
                              <div className={'btn-group'}>
                                <button onClick={() => openEditPopup(element._id, element.fileName, 'slider')}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.708 5.63053C21.098 6.02053 21.098 6.65053 20.708 7.04053L18.878 8.87053L15.128 5.12053L16.958 3.29053C17.1449 3.10328 17.3985 2.99805 17.663 2.99805C17.9276 2.99805 18.1812 3.10328 18.368 3.29053L20.708 5.63053ZM2.99805 20.5005V17.4605C2.99805 17.3205 3.04805 17.2005 3.14805 17.1005L14.058 6.19053L17.808 9.94053L6.88805 20.8505C6.79805 20.9505 6.66805 21.0005 6.53805 21.0005H3.49805C3.21805 21.0005 2.99805 20.7805 2.99805 20.5005Z" fill="#808080" />
                                  </svg>
                                </button>
                                <button onClick={() => openPopupDelete(element._id, element.fileName, 'slider')}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.3007 5.71069C18.1139 5.52344 17.8602 5.4182 17.5957 5.4182C17.3312 5.4182 17.0775 5.52344 16.8907 5.71069L12.0007 10.5907L7.1107 5.70069C6.92387 5.51344 6.67022 5.4082 6.4057 5.4082C6.14119 5.4082 5.88753 5.51344 5.7007 5.70069C5.3107 6.09069 5.3107 6.72069 5.7007 7.11069L10.5907 12.0007L5.7007 16.8907C5.3107 17.2807 5.3107 17.9107 5.7007 18.3007C6.0907 18.6907 6.7207 18.6907 7.1107 18.3007L12.0007 13.4107L16.8907 18.3007C17.2807 18.6907 17.9107 18.6907 18.3007 18.3007C18.6907 17.9107 18.6907 17.2807 18.3007 16.8907L13.4107 12.0007L18.3007 7.11069C18.6807 6.73069 18.6807 6.09069 18.3007 5.71069Z" fill="#808080" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          ) : ''}
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }} className='lsm-popup-settings__slider'>
              <div className='lsm-popup-settings__slider__header'>
                <h4>Cards</h4>
                <button onClick={openPopupInnerCard} style={{ marginTop: '20px' }} type='submit'>Add Card</button>
              </div>
              <div className='lsm-popup-settings__slider__body'>
                <div className={'lsm-popup-settings__slider__body__cards'}>
                  <div className='lsm-slider'>
                    <div className='lsm-grid'>
                      {dashboardCards.map((element, key) => (
                        element.fileUrl ? (
                          <div key={key} href={element.fileLink} className='lsm-card'>
                            <img src={element.fileUrl} alt="image" />
                            <div>
                              <button onClick={() => openEditPopup(element._id, element.fileName, 'card')}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.708 5.63053C21.098 6.02053 21.098 6.65053 20.708 7.04053L18.878 8.87053L15.128 5.12053L16.958 3.29053C17.1449 3.10328 17.3985 2.99805 17.663 2.99805C17.9276 2.99805 18.1812 3.10328 18.368 3.29053L20.708 5.63053ZM2.99805 20.5005V17.4605C2.99805 17.3205 3.04805 17.2005 3.14805 17.1005L14.058 6.19053L17.808 9.94053L6.88805 20.8505C6.79805 20.9505 6.66805 21.0005 6.53805 21.0005H3.49805C3.21805 21.0005 2.99805 20.7805 2.99805 20.5005Z" fill="#808080" />
                                </svg>
                              </button>
                              <button onClick={() => openPopupDelete(element._id, element.fileName, 'card')}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M18.3007 5.71069C18.1139 5.52344 17.8602 5.4182 17.5957 5.4182C17.3312 5.4182 17.0775 5.52344 16.8907 5.71069L12.0007 10.5907L7.1107 5.70069C6.92387 5.51344 6.67022 5.4082 6.4057 5.4082C6.14119 5.4082 5.88753 5.51344 5.7007 5.70069C5.3107 6.09069 5.3107 6.72069 5.7007 7.11069L10.5907 12.0007L5.7007 16.8907C5.3107 17.2807 5.3107 17.9107 5.7007 18.3007C6.0907 18.6907 6.7207 18.6907 7.1107 18.3007L12.0007 13.4107L16.8907 18.3007C17.2807 18.6907 17.9107 18.6907 18.3007 18.3007C18.6907 17.9107 18.6907 17.2807 18.3007 16.8907L13.4107 12.0007L18.3007 7.11069C18.6807 6.73069 18.6807 6.09069 18.3007 5.71069Z" fill="#808080" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ) : ''
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup style={{ maxWidth: '700px' }} trigger={buttonPopupInner} setTrigger={setButtonPopupInner}>
        <UploadSlider type={'slider'} />
      </Popup>
      <Popup style={{ maxWidth: '700px' }} trigger={buttonPopupInnerCards} setTrigger={setButtonPopupInnerCards}>
        <UploadSlider type={'cards'} />
      </Popup>
      <Popup style={{ maxWidth: '700px' }} trigger={buttonPopupInnerDelete} setTrigger={setButtonPopupInnerDelete}>
        <div className={'romove-lsm-dashboard-card'}>
          <h6>REMOVE {currentDeleteItemName}</h6>
          <p>Are you sure you want to delete this?</p>
          <div className={'btn-group'}>
            <button onClick={() => setButtonPopupInnerDelete(false)}>No</button>
            <button onClick={() => removeItemID(currentDeleteItemID, currentDeleteType)}>Yes</button>
          </div>
        </div>
      </Popup>
      <Popup style={{ maxWidth: '700px' }} trigger={buttonPopupInnerEdit} setTrigger={setButtonPopupInnerEdit}>
        <div className={'romove-lsm-dashboard-card'}>
          <h6>EDIT {currentEditItemName}</h6>
          <div className={'edit-lsm-dashboard-card'}>
            <input placeholder={'New Link...'} />
          </div>
          <div className={'btn-group'}>
            <button onClick={() => editItemID(currentEditItemID, currentEditType)}>Save</button>
          </div>
        </div>
      </Popup>

      <div className={'lsm-dashboard-slider'}>
        <Swiper
          navigation={true}
          pagination={{ clickable: true }}
          loop={true}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper"
          autoplay={{delay: 5000}}>
          {
            dashboardSlider.map((element, key) => (
              <SwiperSlide key={key}>
                {element.fileUrl ? (
                  <Link to={element.fileLink} className='lsm-card-dashboard'>
                    <img src={element.fileUrl} alt="slide-01" />
                  </Link>
                ) : ''}
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
      <div className={'lsm-dashboard-cards'}>
        {dashboardCards.map((element, key) => (
          element.fileUrl ? (
            <Link key={key} to={element.fileLink} className={'lsm-dashboard-card'}>
              <img src={element.fileUrl} alt={'lsmCard'} />
            </Link>
          ) : ''
        ))}
      </div>
    </div>
  )
}

export default LSM